
import React,{Component} from 'react';
import {AppContext} from '../Context';

import Trailerpreviewpagetwo from '../Components/Trailerpreviewpagetwo';

import Loader from 'react-loader-spinner'
import ContentLoader from "react-content-loader";

import paypal from '../Img/Paypal.png';
import stripe from '../Img/stripe2.jpg';


let BaseURL = 'https://reactbooks.com/api/';
// Paypal Link
let Cancel = 'https://reactbooks.com/10aec35353f9c4096a71c38654c3d402';
let Success = 'https://reactbooks.com/260ca9dd8a4577fc00b7bd5810298076';
let PaymentListner = 'https://reactbooks.com/api/paypal/payment-listner-trailer.php';
// Live Account PayPal
let paypal_url = 'https://www.paypal.com/cgi-bin/webscr'; 
let paypal_email = 'alwinin@live.com';
//------------------------------------------
// Sandbox Account PayPal
//let paypal_url = 'https://www.sandbox.paypal.com/cgi-bin/webscr'; 
//let paypal_email = 'infooltobusines@gmail.com';
//-------------------------------------------------------------------------------------


class Trailorpreviewpage extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
          
            isEditing:false,
            paynowpaypal: true,
            paynowstripe: false,
            activate: false,
         };
         
         this.packageselected = this.packageselected.bind(this);
     
    
     }

    

      componentDidMount(){
          this.viewtrailorPreview();
          
        this.setState({
            packagePlan: 'strips',
        });
          
    }
    viewtrailorPreview = () => {
        this.context.viewtrailorPreview(this.props.id);
    }
    coupenActivatedtrailor = (video_id) =>{
        this.context.coupenActivatedtrailor(video_id,this.coupenCode.value,this.coupenBookAmount.value,this.coupenBookId.value);
        
        
    }



    //radio button
    packageselected(event) {
      //alert(event.target.value);
      this.setState({
        packagePlan: event.target.value
      });
    }
    
            paypalMode = () => {
                this.setState({
                    paynowpaypal: true,
                    paynowstripe: false
                });

            }
            stripeMode = () => {
                this.setState({
                    paynowpaypal: false,
                    paynowstripe:true
                });

            }
            isActivate = () => {
                this.setState({
                    activate: true,
                });

            }





    render(){
        let allUsers;
        let mainData;
      
       
        allUsers = this.context.all_trailors.map(({video_id,title,video,paymentbook,paymentamount,coupon_code,discount,isEditing}) => {
              //const regex = /(<([^>]+)>)/ig;
              //const descriptionviews = description.replace(regex, '');
            return isEditing === true ? (
                   <div key={video_id}>
                        
                           <div className="form-row">
                             <Trailerpreviewpagetwo  video_id={video_id} title={title} video={video}paymentamount={paymentamount} paymentbook={paymentbook} loadingsssedits ={this.props.loadingedits}/>
                            
             {/* {this.props.desc} */}
             </div>
 
            </div>
            ) :  (
               
                <div key={video_id}>
                <div className="col-md-8">

                <div className="central-metazzz">

    {/* ------------------------------ Loader start ----------------------------------------------------- */}
    
                    {this.props.loadingsss ? 
                    <><Loader type="TailSpin" color="#fd6500" height={67} width={67} style={{textAlign:"center"}} /></>
                    : 
                    <>
                          <button className="zzz" onClick={() => this.context.editModetrailerpreview(video_id)}>Edit</button>
                          <h2 className="create-post">{title}</h2>


                        

                          <div className="col-md-8">
                         <div className="zzz2">
                       
                            
                         <iframe id="ytplayer" type="text/html" className="videosss"
                         src={`https://www.youtube.com/embed/`+video+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 

                                 

                          </div>
                          </div>
                         


                                </>
                             }
                         
     {/* ------------------------------- Loader End ----------------------------------------------------- */}
                        
                        </div>
                        </div>
                        <div className="col-md-4">
                        <div className="central-metazzz">
                        {coupon_code !=='' ? 
                        <h4 className="text-warning2"> Coupon code actived </h4>
                        :  <h4 className="text-warning2"> Have a coupon code? </h4> 
                        }
                      
                        <div className="text-warning2s">
                            <input className="form-control" type="text" ref={(val) => this.coupenCode = val} defaultValue={coupon_code} onClick={this.isActivate} placeholder="Enter The Coupon Code"  />
                            <input type="hidden" ref={(val) => this.coupenBookAmount = val} defaultValue={paymentamount} id="price" />
                            
                            <input type="hidden" ref={(val) => this.coupenBookId = val} defaultValue={video_id} id="pageview" />

                            {coupon_code !=='' ? 
                            <h4 class="pull-right text-danger"> {discount} %Off</h4>
                        :  
                        <>
                        {this.state.activate === true ?
                <button className="btn btn-primary" onClick={() => this.coupenActivatedtrailor(video_id)}>Activate Code</button> 
                        
                        :
                        null

                        }
                         </>
                        }
                            
                        </div>

                        <div className="form-group zzz3">
                            <h6> Total Amount : <span> $ {paymentamount} </span> </h6>
                        </div>


                        <div className="zzz4" >
                        {/* <div className="col-md-12">
                        <input type="radio" className="radiozzz5" id="paypalsqqq"
                           value="paypals" 
                           checked={this.state.packagePlan === "paypals"}
                           onChange={this.packageselected}  onClick={this.paypalMode}/>
                         <label className="radio-labelpay" htmlFor="paypalsqqq"> <img src={paypal} alt="paypal" /> </label>
                         </div> */}
                         <div className="clearfix"></div>
                         <hr />
                         <div className="clearfix"></div>
                         <div className="col-md-12">
                        <input type="radio" className="radiozzz5" id="stripsqqq"
                           value="strips" 
                           checked={this.state.packagePlan === "strips"}
                           onChange={this.packageselected}  onClick={this.stripeMode}/>
                         <label className="radio-labelpay" htmlFor="stripsqqq"> <img src={stripe} alt="stripe" style={{maxWidth: '33px',borderRadius: '3px',marginRight: '7px',marginBottom: '0px'}} /> Credit / Debit Card </label>
                         </div>
                          

                          </div>





{/* ------------------------------------------------------------------------------------------ */}
            {/* --------------- PayPal and Stripe Payment Gateway ----------- */}
{/* ------------------------------------------------------------------------------------------ */}



                     
                      
                       <a href={BaseURL+`stripe/4d4b5a0acd52997e57ab6d28dac58cf2.php?1bda80f2be4d3658e0baa43fbe7ae8c1=`+video_id+`&e5e0376467de2afa1170bf7eb0206810=`+video_id} className="continueButton"> Pay Now   </a>
                       
       
                         </div>
                         </div>
                
                         </div>
                        
          
            );
        });

        if(this.context.all_trailors.length > 0){
            mainData = (
              <div>
                      {allUsers}
              </div>
                   
            );
        }
        else{
            mainData = (
                <div className="alert alert-light" role="alert">
                    <h4 className="alert-heading"> Please Wait </h4>
                    <hr/>
                    <ContentLoader  height={260} width={400} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
                </div>
            );
        }
        return (
            < >
           
                {/* <h3> Pass Value :{this.props.id} </h3> */}
                
                {mainData}
      
     
      
            
        </>  
        );
    }
}
export default Trailorpreviewpage;